import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
//加入http请求
import axios from 'axios'
//加入Element ui主键
import ElementPlus from 'element-plus'
//加入element的主题
import 'element-plus/dist/index.css'
//添加事件管理者'passive'，来阻止'touchstart'事件，让页面更加流畅。 解决chrome下的warning问题
import "default-passive-events";
//加入element ui的message box
import { ElMessageBox, ElMessage } from "element-plus"
//加入echarts 图标组件
import * as echarts from 'echarts'
// 公共样式
import '@/assets/base.css'

// 引入全局组件
import * as filters from '@/filters'

const app = createApp(App)

// 注册全局组件
app.config.globalProperties.$filters = filters

app.use(ElementPlus, { size: 'small' })
app.config.globalProperties.$echarts = echarts
app.config.productionTip = false

// const baseURL = "http://localhost:8093"
// const baseURL = "https://mdcrm.3s-md.com"
// const baseURL = "http://39.105.44.45"
// const baseURL = "https://crm.chinaxdata.com:8861"
let baseURL = ""// "/dev-api"

if(process.env.NODE_ENV == "development") {
  baseURL =  process.env.VUE_APP_BASE_API
}else {
  baseURL = process.env.VUE_APP_BASE_HOST || '';// 后面可能会拼接undefined,所以增加一个为空的判断
}

axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'
app.config.globalProperties.$axios = axios
app.config.globalProperties.$ElMessageBox = ElMessageBox
app.config.globalProperties.$ElMessage = ElMessage
app.config.globalProperties.$userURL = baseURL + "/user-server"
app.config.globalProperties.$customerURL = baseURL + "/customer-crm-server"
app.config.globalProperties.$ugcUrl = baseURL + "/ugc-crm-server"
app.config.globalProperties.$taskUrl = baseURL + "/task-crm-server"
app.config.globalProperties.$fileURL = baseURL + "/file-server"
app.config.globalProperties.$doctorURL = baseURL + "/doctor-server"
app.config.globalProperties.$reportURL = baseURL + "/report-server"
app.config.globalProperties.$sportsCrmUrl = baseURL + "/sports-crm-server"
app.config.globalProperties.$consultationCrmUrl = baseURL + "/consultation-crm-server"


//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
  const role = sessionStorage.getItem('userInfo');
  if (!role && to.path !== '/login') {
    next('/login');
  } else {
    // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
    if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
      this.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
        confirmButtonText: '确定'
      });
    } else {
      //next();

      let path = to.path;
      if (path != undefined && path != null && path.length > 0 && path.startsWith("/")) {
        next();
      } else {
        this.$alert('您没有权限访问该页面！', {
          confirmButtonText: '确定'
        });
      }
    }
  }
})

app.use(router)
app.mount('#app')