// 全局loading
import { ElLoading } from 'element-plus'
import _ from 'lodash'

//loading对象
let loading

// 当前正在请求的数量
let needLoadingRequestCount = 0
// 声明一个对象用于储存请求个数
export const showFullScreenLoading = function (target, message) {
    if (needLoadingRequestCount === 0) {
        startLoading(target, message)
    }
    needLoadingRequestCount++
}

function startLoading(target, message) {
    // 后面这个判断很重要，因为关闭时加了抖动，此时loading对象可能还存在，
    // 但needLoadingRequestCount已经变成0.避免这种情况下会重新创建个loading
    if (needLoadingRequestCount === 0 && !loading) {
        loading = ElLoading.service({
            lock: true,
            text: message || '拼命加载中……',
            background: 'rgba(255, 255, 255, 0.5)',
            target: target || "body"
        })
    }
}

export function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--;
    needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 做个保护,给定的一组数字中的最大值
    tryCloseLoading();
}

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次。防止连续请求时， loading闪烁的问题。
const tryCloseLoading = _.debounce(() => {
   if(needLoadingRequestCount === 0 && loading) {
       loading.close();
       loading = null;
   }
}, 300)