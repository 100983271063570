const UserStorage = {
    setUserInfo:function(userInfo){
        sessionStorage.setItem('userInfo',JSON.stringify(userInfo));
    },
    getUserInfo:function(key){
        const v = sessionStorage.getItem('userInfo');
        if(v == null) return '';
        const json = JSON.parse(v);
        return json[key];
    },
    setMenu:function(menu){
        sessionStorage.setItem('menu',JSON.stringify(menu));
    },
    getMenu:function(){
        const v = sessionStorage.getItem('menu');
        if(v == null) return [];
        const json = JSON.parse(v);
        return json;
    },
    setBtnPermission:function(btnPermission){
        sessionStorage.setItem('btn',JSON.stringify(btnPermission));
    },
    getBtnPermission:function(key){
        const v = sessionStorage.getItem('btn');
        if(v == null) return {};
        const json = JSON.parse(v);
        if(!json.hasOwnProperty(key)) return {};
        return json[key];
    },
    setRouteInfo:function(route){
        sessionStorage.setItem('route',JSON.stringify(route));
    },
    getRouteInfo:function(){
        const v = sessionStorage.getItem('route');
        if(v == null) return [];
        const json = JSON.parse(v);
        return json;
    },    
    clear:function(){
        sessionStorage.clear();
    }
};
export default UserStorage;