import { dateFormat } from "@/api/Tools"

// 处理服务端返回的日期 2024-05-29T14:28:32
export function disposeServerDateFn(str, format = "yyyy-MM-dd HH:mm:ss") {
    if(!str) return '-'
    return dateFormat(new Date(str), format)
}


// 数组转对应枚举
function mapListStatus(list, idField, colorField) {
  return list.reduce((result, item) => {
    result[item[idField]] = item[colorField];
    return result;
  }, {});
}

/**
 * 
 * @param {*} state 门店类别
 */
export const shopTypeList = [
  { id: 1, name: '蔓迪直营诊所', color: '#409EFF' },
  { id: 2, name: '毛囊监测站', color: '#67C23A' },
]

export function shopTypeFn(state, type = 'name') {
  const map = mapListStatus(shopTypeList, 'id', type);
  return map[state]
}

/**
 * 
 * @param {*} state 药品类别
 */
export const medicineTypeList = [
  { id: 1, name: '热门药品', color: '#409EFF' },
  { id: 2, name: '上药工具', color: '#67C23A' },
]

export function medicineTypeFn(state, type = 'name') {
  const map = mapListStatus(medicineTypeList, 'id', type);
  return map[state]
}


/**
 * 
 * @param {*} state 药品类别
 */
export const medicineUrlTypeList = [
  { id: 0, name: '内部小程序', color: '#F56C6C', hint: '路径格式为：pages/tabBar/community/community' },
  { id: 1, name: '外部小程序', color: '#409EFF', hint: '请在对应小程序的微信公众平台上查询appId，互联网医院appId：wx8ad3e7b2d48c93e9' },
  { id: 2, name: 'H5', color: '#67C23A', hint: '路径格式为：https://www.baidu.com/' },
]

export function medicineUrlTypeFn(state, type = 'name') {
  const map = mapListStatus(medicineUrlTypeList, 'id', type);
  return map[state]
}