<template>
    <div class="login">
        <div class="top">
            <div class="top-left">
                <div class="top-left-img"></div>
            </div>
            <div class="top-center"></div>
            <div class="top-right">
                <div class="top-right-text1">MD-CRM</div>
                <div class="top-right-text2">客户管理系统</div>
            </div>
        </div>
        <div class="middle">
            <div class="login-space"></div>
            <div class="login-space"></div>
            <div class="login-space"></div>
            <div class="login-space"></div>
            <div class="login-space login-content">
                <div class="login-div">
                    <el-form :model="loginInfo" :rules="rules" ref="loginInfo">
                        <div class="login-contnt-top">
                            <div class="login-contnt-top-left">欢迎登录</div>
                            <div class="login-contnt-top-right">
                                <!-- <el-button type="primary" style="width:20px;height:20px;" circle >?</el-button> -->
                            </div>
                        </div>
                        <div class="login-contnt-bottom">
                            <div class="login-contnt-bottom-active">
                                <el-form-item label="" prop="userName">
                                    <el-input v-model="loginInfo.userName" class="w-50 m-2" size="large"
                                        placeholder="请输入账号ID" :prefix-icon="Avatar" />
                                </el-form-item>
                            </div>
                            <div class="login-contnt-bottom-active">
                                <el-form-item label="" prop="password">
                                    <el-input type="password" show-password v-model="loginInfo.password" size="large"
                                        placeholder="请输入登录密码" :prefix-icon="Lock"> </el-input>
                                </el-form-item>
                            </div>
                            <div class="login-contnt-bottom-active error-msg">{{ errorMsg }}</div>
                            <!-- <div class="login-contnt-bottom-active login-btn"><el-button type="primary" @click="login('loginInfo')" v-loading.fullscreen.lock="loading">登录</el-button></div> -->
                            <div class="login-contnt-bottom-active login-btn"><el-button type="primary"
                                    @click="login('loginInfo')">登录</el-button></div>
                            <div class="login-contnt-bottom-active support">
                                <!-- <div class="support-left">技术支持：</div> -->
                                <!-- <div class="support-right">
                                    <el-button link
                                        @click="openUrl('https://www.chinaxdata.com')">开发商：国数创凯（上海）信息科技有限公司</el-button>
                                </div> -->
                            </div>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="login-space"></div>
        </div>
        <div class="bottom"> 浙江三生蔓迪药业有限公司 © {{new Date().getFullYear()}} 浙ICP备2021031497号-1 </div>
    </div>
</template>
<script >
import { QuestionFilled, Avatar, Lock, View, Hide } from '@element-plus/icons-vue'
import { asyncPostLoading } from '../api/request';
import md5 from 'js-md5';
import UserStorage from '../api/UserStorage';
export default {
    name: "login",
    setup() {
        return {
            QuestionFilled, Avatar, Lock, View, Hide
        }
    },
    data: function () {
        return {
            loginInfo: {
                userName: '',
                password: ''
            },
            errorMsg: '',
            loading: false,
            rules: {
                password: [
                    { required: true, min: 1, message: '请输入密码！', trigger: 'blur' }
                ],
                userName: [
                    { required: true, min: 1, message: '请输入用户名！', trigger: 'blur' }
                ]
            },
        };
    },
    mounted() {
        // 绑定enter事件
        document.addEventListener("keyup", this.enterKey);
    },
    destroyed() {
        // 销毁enter事件
        document.removeEventListener("keyup", this.enterKey);
    },
    methods: {
        //打开开发商官网
        openUrl(url) {
            window.open(url);
        },
        login(loginInfo) {
            //触发表单验证 
            this.$refs[loginInfo].validate(valid => {
                if (!valid) return;
                //提交登录
                //let Base64 = require('js-base64').Base64; 
                let param = {
                    "userName": this.loginInfo.userName,
                    "password": md5(this.loginInfo.password)
                };
                this.loading = true;

                asyncPostLoading(this.$userURL + "/user/login", param, res => {
                    this.loading = false;

                    if (res.data.head.st != 0) {
                        this.errorMsg = res.data.head.msg;
                        return;
                    }
                    this.$message.success('登录成功');
                    let data = res.data.body;
                    // localStorage.setItem('userId', data.userId);
                    // localStorage.setItem('de', data.de);
                    // localStorage.setItem('chkCode', data.token);
                    // localStorage.setItem('userName', data.userName); 
                    // localStorage.setItem('nickName', data.nickName); 
                    // localStorage.setItem('aid', data.aid);
                    // localStorage.setItem('roleName', data.roleName);
                    UserStorage.setUserInfo(data);

                    this.$router.push('/');
                }, err => {
                    //console.log(err);
                    this.$ElMessage.error(err);
                    this.loading = false;
                });
            });
        },
        //监听回车
        enterKey(event) {
            const componentName = this.$options.name;
            if (componentName == "login") {
                const code = event.keyCode
                    ? event.keyCode
                    : event.which
                        ? event.which
                        : event.charCode;
                if (code == 13) {
                    this.login('loginInfo');
                }
            }
        },
    }
}
</script>

<style scoped>
.login {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
}

.top {
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
}

.top-left {
    position: absolute;
    width: 312px;
    height: 100px;
    background: linear-gradient(90deg, #262739 0%, rgb(20, 97, 120) 76.19%);
}

.top-left-img {
    /* margin-right: 52px; */
    margin-top: 10px;
    float: right;

    width: 128px;
    height: 128px;

    background: url(../assets/logo.png) no-repeat;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    /* border-radius: 10px; */
}

.top-center {
    position: absolute;
    width: 5px;
    height: 98px;
    background: #FFFFFF;
    border-radius: 3px;
    left: 310px;
    margin-top: 1px;
}

.top-right {
    position: absolute;
    width: calc(100% - 315px);
    height: 100px;
    left: 313px;
    background: linear-gradient(90deg, rgb(20, 97, 120) 0%, #0299B5 42.19%);
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.top-right-text1 {
    margin-top: 15px;
    height: 35px;
    line-height: 35px;
    width: 200px;
    text-align: center;
    display: block;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    /* line-height: 28px; */
    /* display: flex; */
    /* align-items: center; */
    /* text-align: center; */
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.top-right-text2 {
    height: 35px;
    line-height: 35px;
    width: 200px;
    text-align: center;
    display: block;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    /* line-height: 28px; */
    /* display: flex; */
    /* align-items: center; */
    /* text-align: center;  */
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.middle {
    position: absolute;
    /* width: calc(100% - 60px); */
    width: 100%;
    height: calc(100% - 147px);
    display: flex;
    background: url(../assets/background.png) no-repeat center / cover;
    background-size: cover;

    /* margin:130px 30px 30px 30px;  */
    margin-top: 100px;
}

.login-div {
    width: 100%;
    max-width: 370px;
    min-width: 370px;
    height: 100%;
    max-height: 395px;
    min-height: 234px;

    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #DCDFE6;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    position: absolute;
}

.login-space {
    height: 100%;
    width: 16.6%;
}

.login-content {
    min-width: 200px;
    min-height: 214px;
    display: flex;
    align-items: center;
}

.login-contnt-top {
    width: calc(100% - 50px);
    border-bottom: 1px solid #DCDFE6;
    margin: 20px 25px 0px 25px;
    display: flex;
}

.login-contnt-top-left {
    width: 50%;
    height: 44px;
    line-height: 44px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    color: #000000;
    text-align: left;
    padding-left: 25px;
}

.login-contnt-top-right {
    width: 50%;
    height: 44px;
    line-height: 44px;
    text-align: right;
    padding-right: 25px;
}

.login-contnt-top-right button {
    background: var(--el-color-primary);
}

.login-contnt-bottom {
    width: calc(100% - 80px);
    margin: 0px 40px 15px 40px;
}

.login-contnt-bottom-active {
    margin-top: 28px;
}

.error-msg {
    height: 18px;
    line-height: 18px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    margin-top: 15px !important;
    color: #F56C6C;
}

.login-btn button {
    width: 100%;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    height: 35px;
    line-height: 35px;
    border-radius: 5px;
}

.support {
    display: flex;
    text-align: center;

    position: absolute;
    bottom: 0px;
    margin-bottom: 15px;
    width: calc(100% - 80px);
}

.support-left {
    width: 50%;
    text-align: right;

    height: 17px;
    line-height: 17px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
}

.support-right {
    width: 100%;
    text-align: center;

    height: 17px;
    line-height: 17px;

    font-family: 'Abhaya Libre';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #606266;
}

.bottom {
    box-sizing: border-box;

    position: absolute;
    width: 100%;
    height: 47px;
    line-height: 46px;
    background: #FFFFFF;
    border-top: 1px solid #DCDFE6;

    font-family: 'Abhaya Libre';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    color: #606266;

    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    bottom: 0;
}

>>>.el-input__icon {
    font-size: 18px !important;
}</style>