<template>
    <div class="head">
        <div class="logo"></div>
        <div class="system-name">
            <div class="system-name-text1">MD-CRM</div>
            <div class="system-name-text2">客户管理系统</div>
        </div>
		<!-- el-icon-caret-top -->
		<div class="hospital-view" @click="onHospitalFn()">
			<el-icon class="el-icon--left"><CaretTop v-if="isHospital" /><CaretBottom v-else /></el-icon>
			康怡邦互联网医院
		</div>
        <!-- <div class="system-space"></div> -->
        <div class="system-user"> 
            <div class="system-user-home"><el-icon style="cursor: pointer;" @click.stop="homeClick"><Menu/></el-icon></div>
            <el-dropdown size="large" @command="handMenu">
                <span class="el-dropdown-link" style="cursor: pointer;">
                    {{nickName}}（{{userName}}）【{{roleName}}】
                    <el-icon class="el-icon--right"><CaretBottom/></el-icon>
                </span>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item command="changePwd" class="el-dropdown-item" style="font-family: 'PingFang SC';font-style: normal;font-weight: 600;font-size: 12px;color: #606266;"><el-icon><Edit/></el-icon>修改密码</el-dropdown-item>
                        <el-dropdown-item command="logout" class="el-dropdown-item" :divided=true style="font-family: 'PingFang SC';font-style: normal;font-weight: 600;font-size: 12px;color: #606266;"><el-icon><SwitchButton/></el-icon>退出登录</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown> 
        </div>
    </div>
    <!-- ----------------------------------------------修改密码---------------------------------------------- -->
    <el-dialog v-model="dialogVisible" width="25%" :show-close="false" :before-close="handleClose" top="30vh"> 
        <template #header="{titleId}">
            <div class="my-header">
                <span :id="titleId" class="titleClass">修改密码</span> 
            </div>
        </template>
        <el-form ref="formData" :model="formData" label-width="10px" :rules="rules" class="dialog-form">
            <el-row>
                <el-col :span="24">
                    <el-form-item label="" prop="oldPwd">
                        <el-input type="password" show-password v-model="formData.oldPwd" size="large" placeholder="请输入原密码"  :prefix-icon="Lock"> </el-input>  
                    </el-form-item>  
                </el-col>  
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="" prop="newPwd">
                        <el-input type="password" show-password v-model="formData.newPwd" size="large" placeholder="请输入新密码"  :prefix-icon="Lock"> </el-input>  
                    </el-form-item>  
                </el-col>  
            </el-row>
            <el-row>
                <el-col :span="24">
                    <el-form-item label="" prop="newPwd1">
                        <el-input type="password" show-password v-model="formData.newPwd1" size="large" placeholder="请输入新密码"  :prefix-icon="Lock"> </el-input>  
                    </el-form-item>  
                </el-col>  
            </el-row>
        </el-form>
        <template #footer>
            <span class="dialog-footer">
                <el-button @click="dialogVisible = false;formData = {};">取消</el-button>
                <el-button type="primary" @click="changePwd('formData')">确定</el-button>
            </span>
        </template>
    </el-dialog>

</template>
<script>
import {SwitchButton,Edit,CaretBottom,HomeFilled,QuestionFilled,Avatar,Lock,View,Hide,Menu,CaretTop } from '@element-plus/icons-vue'
import { asyncPostLoading,asyncPost } from '../api/request'; 
import UserStorage from '../api/UserStorage';
export default { 
    setup(){  
        return {
            //在input框中，使用prefix，就必须在setup中return，否则无法显示图标
            SwitchButton,Edit,CaretBottom,HomeFilled,QuestionFilled,Avatar,Lock,View,Hide,Menu,CaretTop
        } 
    },
    components:{
        SwitchButton,Edit,CaretBottom,HomeFilled,QuestionFilled,Avatar,Lock,View,Hide,Menu,CaretTop
    },
    data:function(){ 
        return {
			isHospital: false, // 是否展示康怡邦互联网医院
            dialogVisible: false,       //弹窗是否可用
            formData:{},                //新增编辑数据
            rules:{
                oldPwd:[
                    { required: true, min: 6, max: 18,message: '必须输入原密码，长度在6到18位之间！', trigger: 'blur' },
                ],
                newPwd:[
                    { required: true, min: 6, max: 18,message: '必须输入新密码，长度在6到18位之间！', trigger: 'blur' },
                    { pattern:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!@#$%^&*=-`~?&])[A-Za-z\d$@$!@#$%^&*=-`~?&]{6,18}$/,message:"密码必须包含字母、数字、特殊字符，且在6到18位之间"}
                ],
                newPwd1:[
                    { required: true, min: 6, max: 18,message: '必须输入新密码，长度在6到18位之间！', trigger: 'blur' },
                    { pattern:/^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!@#$%^&*=-`~?&])[A-Za-z\d$@$!@#$%^&*=-`~?&]{6,18}$/,message:"密码必须包含字母、数字、特殊字符，且在6到18位之间"}
                ] 
            }
        }
    },
    computed:{
        nickName(){ 
            return UserStorage.getUserInfo('nickName');
        },
        userName(){ 
            return UserStorage.getUserInfo('userName');
        },
        roleName(){ 
            return UserStorage.getUserInfo('roleName');
        }
    },
    methods:{
		// 是否展示康怡邦互联网医院
		onHospitalFn() {
			this.isHospital = !this.isHospital;
			this.$emit('onHospitalFn', this.isHospital)
		},
        //默认显示的控制台页
        homeClick(){
            this.$router.push({
                    path: '/console'
                });
        },
        //弹窗其他区域点击不关闭
        handleClose(done){ },
        handMenu(command){
            if(command == "logout") { 
                this.$ElMessageBox.confirm('确认要退出吗?','提示',{confirmButtonText: '确认',cancelButtonText: '取消',type: 'info',})
                .then(() => { 
                     this.logout();  //退出登录
                 }).catch(() => {});
            }else {
                //修改密码
                this.dialogVisible = true;
                this.formData = {};
            } 
        },
        logout(){
            asyncPost(this.$userURL + "/user/logout",{},res => {
                
                this.$router.push('/');
            },err => {
                this.$ElMessage.error(err);
            });  
            UserStorage.clear();
            this.$router.push('/login'); 
        },
        changePwd(formData){
            this.$refs[formData].validate(valid => {
                if(!valid) return; 
                //修改
                let url = this.$userURL + "/user/changePassword";
                if(this.formData['newPwd'] != this.formData['newPwd1']){
                    this.$ElMessage.error("两次密码输入不相同，请重新输入");
                    return;
                }
              
                asyncPostLoading(url,this.formData,res => {
                    if(res.data.head.st != 0){
                        this.$message.error(res.data.head.msg);
                        return;
                    } 
                    this.$ElMessage.success("修改密码成功");
                    this.logout();
                    this.dialogVisible = false;
                },err => {
                    this.$ElMessage.error(err);
                });  
            });
        }
    }
}
</script>

<style scoped>
/* ************************************弹框样式********************************** */
@import "../assets/dialog.css";
.head{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 70px;

    position: absolute;
    left:0px;
    top:0px;

    background: linear-gradient(90deg, #262739 0%, #0299B5 45.83%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    /* display: flex; */
}
.logo{
    margin-left: 24px;
    margin-top: 10px;
    
    width: 72px;
    height: 60px;
     
    background: url(../assets/home-logo.png) no-repeat;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    float:left;
}
.system-name{
    width: 115px;
    height: 70px;
    float:left;
}
.system-name-text1{ 
    width: 100%;
    height: 25px; 
    line-height: 25px;

    margin-top: 10px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    
    
    text-align: center; 
    color: #FFFFFF; 
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.system-name-text2{
    width: 100%;
    height: 25px; 
    line-height: 25px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    
    
    text-align: center; 
    color: #FFFFFF; 
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.hospital-view {
	height: 70px;
	line-height: 70px;
	font-size: 14px;
	color: #FFFFFF;
	cursor: pointer;
	float: left;
	display: flex;
	align-items: center;
}

.system-space{
    width: calc(100% - 638px);
    height: 70px;
}
.system-user{
    /* width:350px; */
    height: 70px;
    line-height: 70px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
     
    display: flex;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

    margin-right:20px;
    float: right;
}
.system-user-home{
    width: 40px;
    text-align: left;
    height: 70px;
    line-height: 75px;
}
.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.el-dropdown span{
    color: #ffffff;
}
.el-sub-menu{
    margin-left: 20px;
    margin-right: 20px;
}
 
 
</style>